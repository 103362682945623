<template>
  <div
    class="page js-scrollToMap openMap--half openMap--full page__blog"
    :class="[{'messageCenterOpen' : this.$store.state.messageCenter.status}]">
    <div class="content">
      <Header
        :data="true"
        :login="true"
        :title="this.$route.matched[0].props.default.header"
        :search="this.$route.matched[0].props.default.headerSearch"
        :subtitle="this.$route.matched[1] ? this.$route.matched[1].props.default.subtitle : null"
        type="blog"
      />
      <BlogMobileMenu />
      <div class="content__inner">
        <router-view/>
      </div>
      <MessageCenter/>
      <Footer type="blog"/>
    </div>
    <RequestSuccess/>
    <RequestPayment/>
    <AircraftSearchPopup/>
    <ThanksPopup/>
  </div>
</template>

<script>
  import Header from '@/components/Header/Header'
  import Footer from '@/components/Footer/Footer'
  import MessageCenter from '@/components/common/MessageCenter/MessageCenter'
  import RequestSuccess from '@/components/common/modals/RequestSuccess'
  import RequestPayment from '@/components/common/modals/RequestPayment'
  import AircraftSearchPopup from '@/components/common/modals/AircraftSearchPopup'
  import BlogMobileMenu from '@/components/Blog/BlogMobileMenu/BlogMobileMenu'
  import ThanksPopup from '@/components/common/modals/ThanksPopup'
  import '@/views/info/info.styl'

  export default {
    name: 'blog-layout',
    components: {
      Header,
      Footer,
      MessageCenter,
      RequestSuccess,
      RequestPayment,
      AircraftSearchPopup,
      ThanksPopup,
      BlogMobileMenu,
    },
    mounted () {
      this.$store.dispatch('getCategories')
      this.$store.dispatch('getSubcategories')
    },
  }
</script>
