<template>
  <div class="blog-mobile-menu">

    <div class="blog-mobile-menu__user">
      <!-- <div class="blog-mobile-menu__avatar"></div> -->
      <div>
        <button class="blog-mobile-menu__search-button" type="button" @click="$router.push('/theflyreport/search')">
          <SvgIcon name="magnifier-dark"/>
        </button>
      </div>
      <div v-if="user.firstName">{{user.firstName}} {{user.lastName}}</div>
      <div class="blog-mobile-menu__signup-or-login" @click="$router.push('/')" v-else>Log in • Sign up</div>
    </div>
    <div class="blog-mobile-menu__categories">
      <span v-for="category of categories" :key="category.id" @click="openCategory(category.attributes.name)">{{  category.attributes.name }}</span>
    </div>
    <div class="blog-mobile-menu__subcategories">
      <a
        v-for="subcategory of subcategories"
        :key="subcategory.id"
         @click="openSubCategory(subcategory.attributes.subcategory_path)"
        :class="{
          'blog-mobile-menu__subcategory--active': $route.path === `/theflyreport/subcategories/${subcategory.attributes.subcategory_path}`
          }">
          {{ subcategory.attributes.name }}
        </a>
        <a
          @click="$router.push('/theflyreport/argus')"
          :class="{
          'blog-mobile-menu__subcategory--active': $route.path === '/theflyreport/argus'
          }">
          Monitor
        </a>
    </div>
    <div class="blog-mobile-menu__links">
      <router-link v-for="link of nav" :key="link.href" :to="link.href">{{ link.text }}</router-link>
    </div>
    <div class="blog-mobile-menu__darkmode">
      <div>Dark mode</div>
      <Toggle :size="'large'" />
    </div>
  </div>
</template>

<script>
  import Toggle from '../../common/Toggle/Toggle'
  import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
  import './BlogMobileMenu.styl'

  export default {
    name: 'BlogMobileMenu',
    data: () => ({
      nav: [
        {
          href: '/about',
          text: 'About us',
        },
        {
          href: '/contact',
          text: 'Contact',
        },
        {
          href: '/disclaimer',
          text: 'Disclaimer',
        },
        {
          href: '/privacy-policy',
          text: 'Privacy',
        },
        {
          href: '/terms-conditions',
          text: 'Terms',
        },
        {
          href: '/theflyreport',
          text: 'FLYNews & The FLY Report',
        },
      ],
    }),
    computed: {
      categories () {
        return this.$store.state.blog.categories.filter(({attributes}) => attributes.showInHeader)
      },
      subcategories () {
        return this.$store.state.blog.subcategories.filter(({attributes}) => attributes.showInHeader)
      },
      user () {
        return this.$store.state.auth.user || {}
      },
    },
    methods: {
      openSubCategory (id) {
        console.log('openSubCategory');
        console.log(id);
        this.$router.push(`/theflyreport/subcategories/${id}`)
      },
      openCategory (name) {
        this.$store.dispatch('toggleBlogMobileMenu')
        setTimeout(() => {
          const categoryTitle = document.querySelector(`a[name=${name}]`);
          if (categoryTitle) {
            categoryTitle.scrollIntoView();
          } else {
            this.$router.push(`/theflyreport/?category=${name}`)
          }
        }, 10)
      },
    },
    components: {
      Toggle,
      SvgIcon,
    },
  }
</script>
